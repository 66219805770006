export function titleCase(string) {
  if (!string) {
    return "-";
  }

  let result = string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  return result;
}
