import axios from "axios";
import {Modal, notification} from "antd";
import {store} from "redux/store";

import {sg} from "constants/GlobalConstant";

import {authContants} from "views/auth-views/login/redux";
import {setDefaultUser} from "redux/actions/User";
import history from "../history";

const FetchInterceptor = axios.create({
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
FetchInterceptor.interceptors.request.use(
  (config) => {
    const {method, url} = config;
    console.log(`Request ${method.toUpperCase()} ${url}: `, config.data);
    const activeUser = localStorage.getItem(authContants.ACTIVE_USER);
    const token = localStorage.getItem("token");
    if (activeUser) {
      config.headers["email"] = activeUser;
    }
    if (!activeUser && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }
    config.headers.Authorization = token;
    config.headers.signature = sg();
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({message: "Failed", description: "Request Error"});
    return Promise.reject(error);
  },
);

// API respone interceptor
FetchInterceptor.interceptors.response.use(
  (response) => {
    const {method, url} = response.config;
    console.log(`Response ${method.toUpperCase()} ${url}: `, response.data);
    return response.data;
  },
  (error) => {
    const {method, url} = error.config;
    console.log(`Response Error ${method.toUpperCase()} ${url}: `, error.response);

    if (error.response && error.response.status && error.response.data) {
      if (error.response.status === 401) {
        Modal.destroyAll();
        localStorage.removeItem(authContants.ACTIVE_USER);
        sessionStorage.clear();
        notification.error({
          message: "Sesi Habis",
          description: "Mohon untuk login kembali!",
        });
        store.dispatch(setDefaultUser());
      } else {
        let detailMessage =
          error.response.data.message === "MISSING_REQUIRED_DATA"
            ? error.response.data.data.error.details[0].message
            : error.response.data.message === "PROCESSING_ERROR"
            ? error.response.data.data
              ? error.response.data.data.message
              : error.response.message
            : error.response.data.message === "UNPROCESSABLE_ENTITY"
            ? error.response.data.data
              ? error.response.data.data.message
              : error.response.message
            : error.response.data.message === "DUPLICATE_DATA"
            ? error.response.data.data
              ? error.response.data.data.message
              : error.response.message
            : error.response.message;

        notification.error({
          message: "Failed",
          description: error.response.data.message
            ? `${error.response.data.message.replaceAll("_", " ")}${
                detailMessage ? ` - ${detailMessage}` : ""
              }`
            : error.response.statusText,
        });
      }
    } else {
      notification.error({
        message: "Permintaan Gagal Diproses",
        description: "Mohon periksa kembali koneksi jaringan Anda!",
      });
    }
    return Promise.reject(error);
  },
);

export default FetchInterceptor;
