import {message, notification} from "antd";
const XLSX = require("xlsx");

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return "dark";
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = (str) => str.replace(/^\s+|\s+$/gm, "");
    const inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = "0" + part;
      }
    });
    return `#${outParts.join("")}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  static displaySuccessNotification(message, description) {
    return notification["success"]({
      message,
      description,
      placement: "topLeft",
      style: {
        marginTop: 50,
      },
    });
  }

  static displayErrorMessage(error) {
    return message.error(error);
  }

  static alphabeticalSorter(a, b) {
    const aValue = a ? a : "-";
    const bValue = b ? b : "-";
    return String(aValue).localeCompare(String(bValue));
  }

  static numberSorter(a, b) {
    const aValue = a ? a : 0;
    const bValue = b ? b : 0;
    return aValue - bValue;
  }

  static modeFormTitle(mode, extra) {
    let isExtra = extra ? ` ${extra}` : "";
    let title = "";
    switch (mode) {
      case "ADD":
        title = `Tambah${isExtra}`;
        break;
      case "VIEW":
        title = `Detail${isExtra}`;
        break;
      case "EDIT":
        title = `Ubah${isExtra}`;
        break;
      default:
        break;
    }
    return title;
  }

  static numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static getValueArr(arrData, id, key) {
    let value = "";
    if (arrData.find((data) => data.id === id)) {
      value = arrData.find((data) => data.id === id)[key];
    }
    return value;
  }

  static thousandSeparator(number) {
    try {
      var parts = number.toString().split(",");
      parts[0] = "Rp " + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    } catch (e) {
      return null;
    }
  }

  static thousandSeparatorPure(number) {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } catch (e) {
      return null;
    }
  }

  static exportExcel(data, name) {
    let result = [];
    let temp = [];
    let tempHead = [];
    for (let count = 0; count < data.length; count++) {
      temp = [];
      for (let prop in data[count]) {
        if (count === 0) {
          temp.push(data[count][prop]);
          tempHead.push(prop);
        } else {
          temp.push(data[count][prop]);
        }
      }
      if (count === 0) {
        result.push(tempHead);
        result.push(temp);
      } else {
        result.push(temp);
      }
    }

    const worksheet = XLSX.utils.aoa_to_sheet(result);
    let newWorkbook = XLSX.utils.book_new();
    const date = new Date();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, "Sheet");
    XLSX.writeFile(
      newWorkbook,
      `export.${name}.${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}.xlsx`,
    );
  }
}

export function calculateDRTotal({price = 0, quantity = 0, discount, discAmount = 0}) {
  let totalPrice = (price ? price : 0) * (quantity ? quantity : 0);

  if (discount && discount.length > 0 && discAmount) {
    let disc1 = (totalPrice * discount[0]) / 100;
    let totalPrice1 = totalPrice - Math.round(disc1);
    let disc2 = (totalPrice1 * discount[1]) / 100;
    let totalPrice2 = totalPrice1 - Math.round(disc2);
    let disc3 = (totalPrice2 * discount[2]) / 100;
    let totalPrice3 = totalPrice2 - Math.round(disc3);
    let disc4 = (totalPrice3 * discount[3]) / 100;
    let totalPrice4 = totalPrice3 - Math.round(disc4);

    return totalPrice4 - discAmount;
  }

  return totalPrice;
}

export default Utils;
export * from "./numberFormatter";
export * from "./getBase64FromFile";
export * from "./useFetchAPI";
export * from "./getUserData";
export * from "./encrypter";
export * from "./titleCase";
export * from "./useQueryParams";
